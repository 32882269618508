@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

borderRadius: 1px;
offset: 150%;
inputHeight: 63px;
transitionEasing: cubic-bezier(.36,1,.62,.98);

background1: #433B7C;
background2: #2C497F;

yellow: #ED6A5E;

@mixin box-shadow($props...) {
    -webkit-box-shadow: $props;
    -moz-box-shadow: $props;
    box-shadow: $props;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin transition($transition...) {
    -moz-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

html, body, .container, #app {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #fff;
    margin: 0 !important;
}

.app {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow-y: scroll;
    background-image: url('https://assets.onestream.live/webassets/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.form-block-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    @include transition(all .85s ease-in-out);
    &--is-login {
        opacity: .92;
        background-color: $background2;
    }
    &--is-signup {
        opacity: .94;
        background-color: $background1;
    }
}

.h3_class {
  font-weight: 300;
  text-align: center;
  margin-bottom: 17px;
  font-size: 13px;
  color: #000000f7;
  letter-spacing: 0px;
  word-spacing: 1px;
}

.ver-hor-align {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.form-block {
  width: 281px;
  color: #b8bec0;
  padding-top: 33px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 20px 3px;
  background: #fff;
  border-radius: 11px;
}

.form-block__header {
    margin-bottom: 20px;
    h1 {
        font-size: 30px;
        margin: 0 0 20px;
    }
}

.form-block__toggle-block {
    position: relative;
    span {
        font-size: 13px;
        font-weight: 300;
        color: rgba(255,255,255,.65);
    }
}

.form-block__input-wrapper {
    height: 2 * $inputHeight;
    position: relative;
    margin-bottom: 2px;
    @include transition(all .25s $transitionEasing .3s);
    .form-block--is-signup & {
        height: 4 * $inputHeight;
        @include transition(all .5s $transitionEasing .2s);
    }
}

.form-group {
    &--signup {
        position: absolute;
        top: 0;
        left: $offset;
        width: 100%;
    }
}

.form-group__input {
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    padding: 14px 20px;
    margin-bottom: 15px;
    background: rgba(255,255,255,.25);

    border: 1.5px solid #dcdcdc;
    border-radius: 3px;
    position: relative;
    display: table;
    border-collapse: separate;
    color: #89949B;
  }

  .form-group__input:focus {
        outline: none;
        border: 1px solid #a5a5a5;
        background: transparent;
    }
    .form-block--is-signup & {
        transform: translateX(-$offset);
    }
    .form-group--login & {
        opacity: 1;
        .form-block--is-signup & {
            opacity: 0;
        }
    }
    .form-group--signup & {
        opacity: 0;
        .form-block--is-signup & {
            opacity: 1;
        }
    }


@for $i from 1 through 4 {
    .form-group__input:nth-of-type(#{$i}) {
        @include transition(transform .35s $transitionEasing $i*.085s, opacity .3s ease-in-out .2s, background .15s ease-in-out, border .3s ease-in-out);
    }
}

.button {
    margin-bottom: 15px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    background: linear-gradient(270deg, #a11453 0%, #e1163c 100%);
    line-height: 1.2;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: .5px;
    margin-top: 0px;
    padding-top: 0.8em;
    padding-right: 3em;
    padding-bottom: 0.8em;
    padding-left: 3em;
    &--primary {
        background: $yellow;
        color: #fff;
    }
    &:hover {
        @include box-shadow(0px 0px 18px 15px rgba(0,0,0,0.15));
    }
}

.button:hover {
  background: #dc143c;
  cursor: pointer;
  font-weight: bold;
}

.os-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}

.hr-styling {
  margin-top: 25px;
  margin-bottom: 25px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.full-width {
    width: 100%;
}

input[type="checkbox"] {
    display: none;
    + label {
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 10px;
            right: 0;
            width: 43px;
            height: 8px;
            background: rgba(255, 255, 255, .4);
            cursor: pointer;
            @include border-radius(50px);
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 1px;
            right: 18px;
            width: 25px;
            height: 25px;
            background: $yellow;
            cursor: pointer;
            @include border-radius(50%);
            @include box-shadow(1px 1px 0px 0px rgba(0,0,0,0.15));
            @include transition(all .3s ease-in-out);
        }
    }
    &:checked + label:after {
        right: 0;
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 16px;
    color: #6062625e;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 16px;
  color: #6062625e;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 16px;
  color: #6062625e;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 16px;
  color: #6062625e;
}

.tooltip {
    padding: 7px;
    @include border-radius(1px);
    background: rgba(0,0,0,.5);
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: -10px;
    right: 0
}
